import React, { FC } from "react"
import { ChakraProvider } from "@chakra-ui/react"

import { Main } from "./Main"
import { Fonts } from "./Fonts"
import type { HtmlProgramData } from "./generated/html_program"

export const App: FC<{ data: HtmlProgramData }> = ({ data }) => (
  /* FIXME */
  <ChakraProvider theme={{}}>
    <Fonts />
    <Main data={data} />
  </ChakraProvider>
)
